import React from "react";
import { graphql, StaticQuery } from "gatsby";

import { Container, Row, Col } from "reusecore/Layout";
import { BasicBanner } from "reusecore/Banner";
import { IconCard } from "reusecore/Card";

import ClientsSectionWrapper from "./clients.style";

const Clients = () => {
  var showdown = require('showdown'), converter = new showdown.Converter();

  return (
    <StaticQuery
      query={graphql`  
        query ClientsQuery {
          allStrapiPage(filter: {name: {eq: "clients"}}) {
            edges {
              node {
                id
                name
                body
                fields {
                    logoUrl
                    bodyHtml
                }
                strapiId
                featuredimage {
                    name
                }
              }
            }
          }
          allStrapiClient(sort: {fields: [fields___sortindexnum, name], order: ASC}) {
            edges {
              node {
                id
                name
                sortindex
                description
                strapiId
                fields {
                    logoUrl
                }
                logo {
                    name
                }
              }
            }
          }
          site {
            siteMetadata { 
              apiUrl
            }
          }
        }
      `}
      render={data => {
        return (
          <ClientsSectionWrapper id="clients">
            <BasicBanner
              title="Clients"
              image={data.site.siteMetadata.apiUrl + data.allStrapiPage.edges[0].node.fields.logoUrl}
            >
              <div
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(data.allStrapiPage.edges[0].node.body) }}
              />
            </BasicBanner>
            <Container>
              <Row>
                {data.allStrapiClient.edges.map((node, index) =>
                  <Col xs={12} sm={4} md={3} lg={2} key={index}>
                    <IconCard icon={data.site.siteMetadata.apiUrl + node.node.fields.logoUrl} />
                  </Col>
                )}
              </Row>
            </Container>
          </ClientsSectionWrapper>
        )
      }}
    />
  );
};

export default Clients;
