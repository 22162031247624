import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import Button from "reusecore/Button";
import VintageBox from "reusecore/VintageBox";
import Particle from "reusecore/Particle";

import { FaWrench, FaLightbulb } from "react-icons/fa";

import BannerSectionWrapper from "./banner.style";

const BannerDefault = () => {
  return (
    <BannerSectionWrapper id="home">
      <Particle />
      <Container fullWidthSM>
        <Row>
          <Col xs={12} sm={7} md={6} lg={7}>
            <SectionTitle
              className="section-title"
              leftAlign={true}
              UniWidth="100%"
            >
              {/* <h4>30% off for new users for 1 month</h4> */}
              <h1>
                <span>Cyder SG</span>
              </h1>
            </SectionTitle>
            <p>
              DIGITAL TRANSFORMATION BY MEANS OF BESPOKE MODERN APPLICATION DEVELOPMENT WITH AGILE & DEVOPS
            </p>
            <VintageBox right={true} vintageOne={true}>
              <AnchorLink to="/#services">
                <Button className="banner-btn one">
                  <FaWrench className="icon-left" />
                  Services
              </Button>
              </AnchorLink>
              <AnchorLink to="/#solutions">
                <Button className="banner-btn two">
                  <FaLightbulb className="icon-left" />
                  Solutions
              </Button>
              </AnchorLink>
            </VintageBox>
          </Col>
        </Row>
      </Container>
    </BannerSectionWrapper>
  );
};

export default BannerDefault;
