import React from "react";
import {ThemeProvider} from 'styled-components';

import Layout from "../components/layout" 
import SEO from "../components/seo" 

import Navigation from 'sections/Navigation'; 
import Banner from 'sections/Banner-default'; 
import WhatWeDo from 'sections/WhatWeDo';
import Company from 'sections/Company';
import Leadership from 'sections/Leadership';
import Services from 'sections/Services';
import Solutions from 'sections/Solutions';
import Partners from 'sections/Partners';
import Clients from 'sections/Clients';
import Contact from 'sections/Contact';
import Footer from 'sections/Footer';

import { GlobalStyle } from 'sections/app.style';
import theme from 'theme/app/themeStyles';

const IndexPage = () => (
  <ThemeProvider theme={theme}> 
    <GlobalStyle />
     <Layout>
      <SEO title="Cyder SG" />
      <Navigation />
      <Banner />
      <WhatWeDo />
      <Company />
      {/* <Leadership /> */}
      <Services />
      <Solutions />
      <Partners />
      <Clients />
      <Contact />
      <Footer />
    </Layout>
  </ThemeProvider>
)

export default IndexPage
