import React from "react";
import { graphql, StaticQuery } from "gatsby";

import { Container, Row, Col } from "reusecore/Layout";
import { BasicCard } from "reusecore/Card";
import { BasicBanner } from "reusecore/Banner";

import ServicesSectionWrapper from "./services.style";

const Services = () => {
  var showdown = require('showdown'), converter = new showdown.Converter();

  return (
    <StaticQuery
      query={graphql`  
        query ServicesQuery {
          allStrapiPage(filter: {name: {eq: "services"}}) {
              edges {
                node {
                  id
                  name
                  body
                  fields {
                      logoUrl
                      bodyHtml
                  }
                  strapiId
                  featuredimage {
                      name
                  }
                }
              }
            }
            allStrapiProfessionalservice(sort: {fields: [fields___sortindexnum, name], order: ASC}) {
              edges {
                node {
                  id
                  name
                  summary
                  sortindex
                  strapiId
                  fields {
                      logoUrl
                  }
                  logo {
                      name
                  }
                }
              }
            }
            site {
              siteMetadata { 
                apiUrl
              }
            }
        }
      `}
      render={data => {
        return (
          <ServicesSectionWrapper id="services">
            <BasicBanner
              title="Services"
              image={data.site.siteMetadata.apiUrl + data.allStrapiPage.edges[0].node.fields.logoUrl}
            >
              <div
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(data.allStrapiPage.edges[0].node.body) }}
              />
            </BasicBanner>
            <Container fullWidthSM>
              <Row>
                {data.allStrapiProfessionalservice.edges.map((node, index) =>
                  <Col xs={12} sm={6} md={6} key={index}>
                    <BasicCard
                      index={index}
                      name={node.node.name}
                      description={node.node.summary}
                      icon={data.site.siteMetadata.apiUrl + node.node.fields.logoUrl}
                    />
                  </Col>
                )}
              </Row>
            </Container>
          </ServicesSectionWrapper>
        )
      }}
    />
  );
};

export default Services;
