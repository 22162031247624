import React, { useRef } from "react";
import Slider from "react-slick";
import { graphql, StaticQuery } from "gatsby";

import Icon from "assets/images/app/integrations/arrow.png";

import { SliderItem } from "reusecore/SlickSlider";
import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import { BasicBanner } from "reusecore/Banner";

import SolutionsSectionWrapper from "./solutions.style";

const Solutions = () => {
  var showdown = require('showdown'), converter = new showdown.Converter();
  const sliderRef = useRef();

  const scrollToIndex = (index) => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  }

  return (
    <StaticQuery
      query={graphql`  
        query SolutionsQuery {
          allStrapiPage(filter: {name: {eq: "homeSolutions"}}) {
              edges {
                node {
                  id
                  name
                  body
                  fields {
                      logoUrl
                      bodyHtml
                  }
                  strapiId
                  featuredimage {
                      name
                  }
                }
              }
            }
            allStrapiSolution(sort: {fields: [fields___sortindexnum, name], order: ASC}) {
              edges {
                node {
                  id
                  name
                  summary
                  body
                  sortindex
                  strapiId
                  fields {
                      logoUrl
                      bodyWithImage
                  }
                  logo {
                      name
                  }
                }
              }
            }
            site {
              siteMetadata { 
                apiUrl
              }
            }
          }
        `
      }
      render={data => {
        const solutions = data.allStrapiSolution.edges;

        const settings = {
          autoplay: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1
        };

        return (
          <SolutionsSectionWrapper id="solutions">
            <BasicBanner
              title="Solutions"
              image={data.site.siteMetadata.apiUrl + data.allStrapiPage.edges[0].node.fields.logoUrl}
            >
              <div
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(data.allStrapiPage.edges[0].node.body) }}
              />
            </BasicBanner>
            <Container>
              <Row Vcenter={true}>
                <Col xs={12} sm={6}>
                  <div className="solutions__block__wrap">
                    <div className="solutions__block">
                      <a onClick={() => scrollToIndex(0)}>
                        <div className="solutions__block__inner">
                          <img src={data.site.siteMetadata.apiUrl + solutions[0].node.fields.logoUrl} alt="cyder solution" />
                          <h3>{solutions[0].node.name}</h3>
                          <div className="solutions-arrow">
                            <img src={Icon} alt="arrow" />
                          </div>
                        </div>
                      </a>
                      <a onClick={() => scrollToIndex(1)}>
                        <div className="solutions__block__inner">
                          <img src={data.site.siteMetadata.apiUrl + solutions[1].node.fields.logoUrl} alt="cyder solution" />
                          <h3>{solutions[1].node.name}</h3>
                          <div className="solutions-arrow">
                            <img src={Icon} alt="arrow" />
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="solutions__block right">
                      <a onClick={() => scrollToIndex(2)}>
                        <div className="solutions__block__inner">
                          <img src={data.site.siteMetadata.apiUrl + solutions[2].node.fields.logoUrl} alt="cyder solution" />
                          <h3>{solutions[2].node.name}</h3>
                          <div className="solutions-arrow">
                            <img src={Icon} alt="arrow" />
                          </div>
                        </div>
                      </a>
                      <a onClick={() => scrollToIndex(3)}>
                        <div className="solutions__block__inner">
                          <img src={data.site.siteMetadata.apiUrl + solutions[3].node.fields.logoUrl} alt="cyder solution" />
                          <h3>{solutions[3].node.name}</h3>
                          <div className="solutions-arrow">
                            <img src={Icon} alt="arrow" />
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={6} className="solutions-content-wrap">
                  <Slider ref={sliderRef} {...settings}>
                    {solutions.map((x, index) =>
                      <SliderItem key={index}>
                        <SectionTitle
                          className="section-title"
                          leftAlign={true}
                          UniWidth="100%"
                        >
                          <h2>
                            <span>{x.node.name}</span>
                          </h2>
                        </SectionTitle>
                        <p className="solutions-text">{x.node.summary}</p>
                      </SliderItem>
                    )}
                  </Slider>
                </Col>
              </Row>
            </Container>
          </SolutionsSectionWrapper>
        )
      }}
    />
  );
};

export default Solutions;
