import styled from 'styled-components';

import Bg1 from 'assets/images/app/features/bg-1.png';
import Bg2 from 'assets/images/app/features/bg-2.png';

import Vintage1 from 'assets/images/app/features/vintage-1.png';
import Vintage2 from 'assets/images/app/features/vintage-2.png';

export const BasicCardWrapper = styled.div`
   .card-icon-block{
        display: flex;
        height: 70px;
        width: 100px;
        align-items: center;
        margin-bottom: 25px;
        position: relative;

        img{
            display: block;
            margin: 0 auto;
            height: 70px;
        }
   }
    .card-block{
        .card-icon-block{
            &:before{
                position: absolute;
                display: inline-block;
                top: -3px;
                right: 0px;
                content: '';
                border-radius: 50%;
                z-index: -1;
                opacity: 0;
                transition: all 0.3s linear;
            }
        } 
        &:hover{
            .card-icon-block{
                &:before{
                    opacity: 1;
                    right: -10px;
                }
            }
        }
        &.v1{
            .card-icon-block{
                background: url(${Bg1}) no-repeat;
                background-size: contain;
                &:before{
                    background: url(${Vintage1});
                }
            }
        }
        &.v2{
            .card-icon-block{
                background: url(${Bg2}) no-repeat;
                background-size: contain;
                &:before{
                    background: url(${Vintage2});
                }
            }
        }

        
        h3{
            font-size: 22px; 
            margin-bottom: 15px;
            font-weight: 600;
            line-height: 30px;
        }
        p{
            margin: 0 0 50px 0;
            font-weight: 300;
        }
    }
    @media only screen and (max-width: 568px) {
        .card-block{
            text-align: center;
        }
        .card-icon-block{
            margin: 0 auto 25px auto;
        }
    }
`

export const IconCardWrapper = styled.div`
    padding: 10px 20px 10px 20px;
    text-align: center;
    .icon-card-inner{
        padding: 0px;
    }
    @media only screen and (max-width: 912px){
        padding: 10px 15px 10px 15px;
    }
    @media only screen and (max-width: 400px){
        img{
            margin: 0 auto;
        }
    }
`
export const ShortCardWrapper = styled.div`
    padding: 0 0 30px 0;
    height: 100%;
    text-align: center;
    .short-card-wrapper{
        height: 100%;
        padding: 20px;
        box-shadow: 0px 0px 15px ${props => props.theme.shadowLightColor};
        border-radius: 4%;
    }
    .short-icon-wrapper{
        padding: 10px 10px 15px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 125px;
        img{
            max-height: 100%;
        }
    }
    .short-description-wrapper{
        text-align: left;
        font-weight: 300;
    }
    @media only screen and (max-width: 912px){
        padding: 0 10px 30px 10px;
    }
`