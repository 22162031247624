import React from "react";
import { ShortCardWrapper } from "./card.style";

const ShortDescriptionCard = ({ description, icon }) => {
  return (
    <ShortCardWrapper>
      <div className="short-card-wrapper">
        <div className="short-icon-wrapper">
          <img src={icon} alt="icon" />
        </div>
        <div className="short-description-wrapper">
          {description}
        </div>
      </div>
    </ShortCardWrapper>
  );
};

export default ShortDescriptionCard;