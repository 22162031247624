import React from "react";
import { BasicCardWrapper } from "./card.style";

const BasicCard = ({ name, description, icon, index }) => {
  return (
    <BasicCardWrapper>
      <div className={`card-block ${index % 2 == 1 ? "" : ""}`}>
        <div className="card-icon-block">
          <img src={icon} alt="icon" />
        </div>
        <h3>{name}</h3>
        <p>{description}</p>
      </div>
    </BasicCardWrapper>
  );
};

export default BasicCard;