import React from "react";
import { graphql, StaticQuery } from "gatsby";

import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import { BasicCard } from "reusecore/Card";

import WhatWeDoSectionWrapper from "./whatWeDo.style";

const WhatWeDo = () => {
  return (
    <StaticQuery
      query={graphql`
        query WhatWeDoQuery {
          allStrapiWhatwedo(sort: {fields: [fields___sortindexnum, name], order: ASC}) {
              edges {
                  node {
                    fields {
                      logoUrl
                    }
                    name
                    id
                    summary
                    sortindex
                  }
              }
          }
          site {
              siteMetadata { 
                apiUrl
              }
            }
        }
      `}
      render={data => {
        return (

          <WhatWeDoSectionWrapper id="whatWeDo">
            <Container fullWidthSM>
              <SectionTitle UniWidth="65%">
                <h4>What We Do</h4>
                <h2>We believe in providing
                  <br />
                  <span>best solutions</span> and <span>quality services</span>.
                </h2>
              </SectionTitle>
              <Row>
                {data.allStrapiWhatwedo.edges.map((node, index) =>
                  <Col xs={12} sm={6} md={6} key={index}>
                    <BasicCard 
                      index={index} 
                      name={node.node.name} 
                      description={node.node.summary} 
                      icon={data.site.siteMetadata.apiUrl + node.node.fields.logoUrl} 
                    />
                  </Col>
                )}
              </Row>
            </Container>
          </WhatWeDoSectionWrapper>
        )
      }}
    />
  );
};

export default WhatWeDo;
