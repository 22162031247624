import React from "react";
import { IconCardWrapper } from "./card.style";

const IconCard = ({ icon }) => {
    return (
        <IconCardWrapper>
            <div className="icon-card-inner">
                <img src={icon} alt="icon" />
            </div>
        </IconCardWrapper>
    );
};

export default IconCard;