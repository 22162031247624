import React from "react";

import { Container, Row, Col } from "reusecore/Layout";
import Button from "reusecore/Button";
import VintageBox from "reusecore/VintageBox";
import SectionTitle from "reusecore/SectionTitle";
import Particle from "reusecore/Particle";

import BasicBannerWrapper from "./banner.style";

const BasicBanner = ({ title, children, image }) => {
  return (
    <BasicBannerWrapper>
      <Container fullWidthSM>
        <Row>
          <Col>
            <SectionTitle
              className="section-title"
              leftAlign={true}
              UniWidth="100%"
            >
              <h1>
                <span>{title}</span>
              </h1>
            </SectionTitle>
          </Col>
        </Row>
        <Row className="image-block">
          <div className="image-wrapper">
            <img src={image} />
          </div>
          <Col md={12} lg={7}>
            <div>{children}</div>
          </Col>
        </Row>
      </Container>
    </BasicBannerWrapper>
  );
};

export default BasicBanner;
