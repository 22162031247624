import React from "react";
import { graphql, StaticQuery } from "gatsby";

import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";

import Icon1 from "assets/images/classic/features/01.svg";
import Icon5 from "assets/images/classic/features/05.svg";
import Icon6 from "assets/images/classic/features/06.svg";
import { BasicBanner } from "reusecore/Banner";

import CompanySectionWrapper from "./company.style";

const Company = () => {
  var showdown = require('showdown'), converter = new showdown.Converter();

  return (
    <StaticQuery
      query={graphql`  
        query CompanyQuery {
          allStrapiPage(filter: {name: {eq: "company"}}) {
              edges {
                node {
                  id
                  name
                  body
                  fields {
                      logoUrl
                      bodyHtml
                  }
                  strapiId
                  featuredimage {
                      name
                  }
                }
              }
            }
            site {
              siteMetadata { 
                apiUrl
              }
            }
        }
      `}
      render={data => {
        return (
          <CompanySectionWrapper id="company">
            {/* <BasicBanner
              title="Comapny"
              image={data.site.siteMetadata.apiUrl + data.allStrapiPage.edges[0].node.fields.logoUrl}
            >
              <div
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(data.allStrapiPage.edges[0].node.body) }}
              />
            </BasicBanner> */}
            <Container>
              <SectionTitle UniWidth="65%">
                <h4>Company</h4>
                <h2>
                  <span>Our Core Values</span>
                </h2>
              </SectionTitle>
              <Row>
                <Col xs={12} sm={6} lg={4}>
                  <div className="single-item">
                    <div className="item-head">
                      <div className="item-icon">
                        <img src={Icon1} alt="appion app about icon" height="60px" />
                      </div>
                      <h3>Our Vision</h3>
                    </div>
                    <p>To be a World Class Secure Software and DevSecOps provider.</p>
                  </div>
                </Col>
                <Col xs={12} sm={6} lg={4}>
                  <div className="single-item">
                    <div className="item-head">
                      <div className="item-icon">
                        <img src={Icon6} alt="appion app about icon" height="60px" />
                      </div>
                      <h3>Our Mission</h3>
                    </div>
                    <p>
                      We are a modern software development company and we bring value to our customers and partners
                      through our delivery of total, integrated quality solutions and support.
              </p>
                  </div>
                </Col>
                <Col xs={12} sm={6} lg={4}>
                  <div className="single-item">
                    <div className="item-head">
                      <div className="item-icon">
                        <img src={Icon5} alt="appion app about icon" height="60px" />
                      </div>
                      <h3>Our Values</h3>
                    </div>
                    <p>
                      We understand that what makes each organisation different is the People.
                      Our people perform SERVICE with Passion.
                      Our people ensure QUALITY with Patience.
                      Our prople produce RESULT with Pride.
              </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </CompanySectionWrapper>
        )
      }}
    />
  );
};

export default Company;
